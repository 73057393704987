<template>
 <div>
   <docu-about></docu-about>
 </div>
</template>

<script>
import DocuAbout from "@/views/component/docuCommon/docuAbout";

export default {
  name: "MtrlAddAbout",
  components: {DocuAbout}
}
</script>

<style scoped>

</style>