<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea">
      <editHeader
        :statusShow="false"
        :isShow="isShow"
        :btn="btn"
        :strForm="mtrlForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('mtrlForm')"
      />
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新 </el-button>
      <el-button
        @click="drawer = true"
        v-if="specialDataFlag && isShow && mtrlForm.status === 2"
        size="small"
        type="warning"
        class="vd_export1"
        >分享账套
      </el-button>
    </div>
    <el-row>
      <el-col :md="8" :offset="7" v-if="warningTitle">
        <div class="vd_color_red">!{{ warningTitle }}</div>
      </el-col>
    </el-row>
    <el-form ref="mtrlForm" :model="mtrlForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row>
        <el-col :md="6">
          <el-form-item label="物料图片">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :data="dataBody"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <imgPopover v-if="mtrlForm.imge_id" :imgeUrl="mtrlForm.imge_url" :imgeUrlO="mtrlForm.imge_urlO"></imgPopover>
              <i v-else class="el-icon-plus avatar-uploader-icon vg_pointer" :class="{ vd_cussor: isShow }"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="物料编号" prop="mtrl_no">
            <el-tooltip class="item" effect="dark" content="仅限两位大写英文字母" placement="top-center">
              <el-input class="mtrlNo" v-model="mtrlForm.mtrl_no" @input="mtrlNoInput" placeholder="例:CR" show-word-limit>
                <template slot="prepend">{{ mtrlForm.acct_no }}</template>
                <template slot="append">{{ mtrlForm.mtrl_color }}<span v-if="mtrlForm.mtrl_color">C</span>{{ num_no }}</template>
              </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="物料名称" prop="mtrl_name">
            <el-input v-model="mtrlForm.mtrl_name" maxlength="30" show-word-limit placeholder="请填写物料名称"></el-input>
          </el-form-item>
          <el-form-item label="物料类型" prop="mtrl_type">
            <el-select v-model="mtrlForm.mtrl_type" placeholder="请选择物料类型" clearable>
              <el-option v-for="item in mtrlType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="安全库存" prop="mtrl_stock_limit">
            <el-input
              v-model="mtrlForm.mtrl_stock_limit"
              @input="val => (mtrlForm.mtrl_stock_limit = keep4Decimal(val))"
              maxlength="7"
              show-word-limit
              placeholder="请填写库存警告数量"
            ></el-input>
          </el-form-item>
          <el-form-item label="物料克重" prop="mtrl_weight">
            <el-input
              v-model="mtrlForm.mtrl_weight"
              @input="mtrlForm.mtrl_weight = helper.keepTNum1(mtrlForm.mtrl_weight)"
              @blur="mtrlForm.mtrl_weight = helper.retain1(mtrlForm.mtrl_weight, 2, 10000)"
              maxlength="7"
              show-word-limit
              placeholder="请填写物料克重"
            >
              <template slot="append">克</template>
            </el-input>
          </el-form-item>
          <el-form-item label="物料单价" prop="mtrl_price">
            <el-input
              v-model="mtrlForm.mtrl_price"
              @input="mtrlForm.mtrl_price = helper.keepTNum1(mtrlForm.mtrl_price)"
              @blur="mtrlForm.mtrl_price = helper.calcPrice(mtrlForm.mtrl_price, 4, 1000000)"
              maxlength="11"
              show-word-limit
              placeholder="请填写物料单价"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="物料规格" prop="mtrl_spec">
            <el-input
              type="textarea"
              rows="1"
              v-model="mtrlForm.mtrl_spec"
              maxlength="50"
              show-word-limit
              placeholder="请填写物料规格"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="潘通色号" v-if="mtrlForm.mtrl_type === 0" prop="mtrl_color">
            <el-tooltip class="item" effect="dark" content="仅限四位数字" placement="top-start">
              <el-input class="mtrlcolor" v-model="mtrlForm.mtrl_color" @input="mtrlColorInput" show-word-limit placeholder="仅限四位数字">
                <template slot="append"><span v-if="mtrlForm.mtrl_color">C</span></template>
              </el-input>
            </el-tooltip>
          </el-form-item>

          <!-- <el-form-item label="供应商户" prop="supp_id">
            <el-select
              v-model="mtrlForm.supp_id"
              placeholder="请选择供应商"
              clearable
            >
              <el-option
                v-for="item in suppType"
                :key="item.supp_id"
                :label="item.supp_name"
                :value="item.supp_id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="物料单位" prop="mtrl_unit">
            <el-select v-model="mtrlForm.mtrl_unit" placeholder="请选择物料类型" clearable>
              <el-option v-for="item in mtrlTypeGroupOpt" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物料毛高" prop="mtrl_thick">
            <el-input
              v-model="mtrlForm.mtrl_thick"
              @input="mtrlForm.mtrl_thick = helper.keepTNum1(mtrlForm.mtrl_thick)"
              @blur="mtrlForm.mtrl_thick = helper.retain1(mtrlForm.mtrl_thick, 2, 1000)"
              maxlength="6"
              show-word-limit
              placeholder="请填写物料毛高"
            >
              <template slot="append">毫米</template>
            </el-input>
          </el-form-item>
          <el-form-item label="物料门幅" prop="mtrl_width">
            <el-input
              v-model="mtrlForm.mtrl_width"
              maxlength="9"
              show-word-limit
              @blur="mtrlForm.mtrl_width = helper.calcPrice(mtrlForm.mtrl_width, 4, 10000)"
              @input="mtrlForm.mtrl_width = helper.keepTNum1(mtrlForm.mtrl_width)"
              placeholder="请填写物料门幅"
            >
              <template slot="append">米</template>
            </el-input>
          </el-form-item>
          <el-form-item label="物料成分" prop="mtrl_comp">
            <el-select v-model="mtrlForm.mtrl_comp" placeholder="请选择物料成分" clearable>
              <el-option v-for="item in compType" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="专属客户" prop="cust_name">
            <el-select filterable v-model="mtrlForm.cust_name" placeholder="请选择" clearable>
              <el-option v-for="item in custList" :key="item.value" :label="item.label" :value="item.label"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="其他备注" prop="mtrl_remark">
            <el-input
              type="textarea"
              rows="1"
              v-model="mtrlForm.mtrl_remark"
              maxlength="150"
              show-word-limit
              placeholder="请填写物料备注"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="mtrlForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
    <el-drawer :visible.sync="drawer" :direction="direction" :before-close="handleCloseDrawer">
      <div class="vd_draw">
        <el-card>
          <el-button type="success" class="vg_mb_16" size="small" v-if="!acctPreseFlag" @click="acctPreseFlag = true">编辑</el-button>
          <el-button type="primary" class="vg_mb_16" size="small" v-if="acctPreseFlag" @click="preseType()">保存</el-button>
          <el-button class="vg_mb_16" size="small" v-if="acctPreseFlag" @click="cancelType()">取消</el-button>
          <el-button type="primary" size="small" v-if="acctPreseFlag" plain @click="addType()">添加</el-button>
          <el-table :data="mtrlForm.mtrl_acct_list" border>
            <el-table-column label="账套名称">
              <template slot-scope="scope">
                <el-select
                  :disabled="!acctPreseFlag"
                  size="small"
                  @change="acctSelect(scope)"
                  v-model="mtrlForm.mtrl_acct_list[scope.$index].acct_id"
                  placeholder="请选择账套"
                  :clearable="mtrlForm.role_status === 1"
                >
                  <el-option
                    v-for="item in acctCopy"
                    :key="item.acct_id"
                    :label="item.acct_no"
                    :value="item.acct_id"
                    :disabled="item.disabled"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column align="center" width="150px">
              <template slot-scope="scope">
                <el-link :disabled="!acctPreseFlag" type="danger" class="vg_cursor" @click="delType(scope)">移除</el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { get, getNoCatch, post } from '@api/request';
import { mtrlAPI } from '@api/modules/mtrl';
import { optnAPI } from '@api/modules/optn';
import { acctAPI } from '@api/modules/acct';
import editHeader from '@/views/component/editHeader';
import { imgeAPI } from '@api/modules/imge';
import inputUser from '@/views/component/inputUser';
import imgPopover from '@/views/component/imgPopover';
import { keep4Decimal } from '@assets/js/regExUtil';
import { custAPI } from '@api/modules/cust';
import { cloneDeep } from 'lodash';

export default {
  name: 'mtrlEditMain',
  components: {
    editHeader,
    inputUser,
    imgPopover
  },
  data() {
    return {
      rules: {
        mtrl_no: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        mtrl_name: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_type: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_unit: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_thick: [{ required: false, trigger: ['blur', 'change'], message: ' ' }],
        mtrl_stock_limit: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_color: [{ required: false, trigger: ['blur', 'change'], message: ' ' }],
        supp_id: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_weight: [{ required: false, trigger: 'blur', message: ' ' }],
        mtrl_comp: [{ required: false, trigger: 'blur', message: ' ' }],
        mtrl_price: [{ required: false, trigger: 'blur', message: ' ' }],
        mtrl_width: [{ required: false, trigger: 'blur', message: ' ' }],
        mtrl_spec: [{ required: false, trigger: 'blur', message: ' ' }]
      },
      rules2: {
        mtrl_no: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        mtrl_name: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_type: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_unit: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_thick: [{ required: false, trigger: ['blur', 'change'], message: ' ' }],
        mtrl_stock_limit: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_color: [{ required: false, trigger: ['blur', 'change'], message: ' ' }],
        supp_id: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_weight: [{ required: false, trigger: 'blur', message: ' ' }],
        mtrl_comp: [{ required: false, trigger: 'blur', message: ' ' }],
        mtrl_price: [{ required: false, trigger: 'blur', message: ' ' }],
        mtrl_width: [{ required: false, trigger: 'blur', message: ' ' }],
        mtrl_spec: [{ required: false, trigger: 'blur', message: ' ' }]
      },
      mtrlForm: {
        mtrl_no: '',
        cust_name: '',
        mtrl_name: '',
        mtrl_type: null,
        mtrl_spec: '',
        mtrl_color: '',
        mtrl_weight: '',
        mtrl_width: null,
        mtrl_unit: '',
        imge_id: null,
        imge_url: '',
        imge_urlO: '',
        mtrl_thick: '',
        mtrl_comp: '',
        mtrl_stock_limit: '',
        mtrl_price: '',
        mtrl_remark: '',
        supp_id: '',
        user_id: null,
        stff_id: null,
        dept_id: null,
        status: 0,
        key: 0
      },
      key: 0,
      isShow: true,
      uploadUrl: this.helper.modePrepend(imgeAPI.uploadImage),
      btn: {},
      stffForm: {},
      imgForm: {
        imge_id: null,
        imge_url: null,
        imge_urlO: null
      },
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      },
      mtrlType: [
        { id: 0, label: '原面料' },
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' }
      ],
      mtrlTypeGroupOpt: [],
      suppType: [],
      show: false,
      loadingFlag: true,
      compType: [],
      num_no: null,
      warningTitle: '',
      drawer: false,
      direction: 'rtl',
      acctCopy: [],
      mtrlAcctCope: [],
      specialDataFlag: false,
      acctPreseFlag: false,
      custList: []
    };
  },
  created() {
    this.initData();
  },
  watch: {
    'mtrlForm.mtrl_type'(newVal) {
      switch (newVal) {
        case 0:
          this.rules.mtrl_comp[0].required = true;
          this.rules.mtrl_price[0].required = true;
          this.rules.mtrl_width[0].required = true;
          this.rules.mtrl_thick[0].required = true;
          this.rules.mtrl_weight[0].required = true;
          this.rules.mtrl_color[0].required = true;
          break;
        case 1:
          this.rules.mtrl_comp[0].required = true;
          this.rules.mtrl_price[0].required = true;
          this.rules.mtrl_spec[0].required = true;
          this.rules.mtrl_width[0].required = false;
          this.rules.mtrl_thick[0].required = false;
          this.rules.mtrl_weight[0].required = false;
          this.rules.mtrl_color[0].required = false;
          break;
        case 3:
          this.rules = cloneDeep(this.rules2);
      }
    }
  },
  methods: {
    keep4Decimal,
    initData() {
      this.loadingFlag = true;
      this.getmtrlInfo();
      this.getMtrlType();
      this.getCompType();
      this.getCustList();
      this.getAccts();
      if (this.$cookies.get('userInfo').acct_id === 1) {
        this.specialDataFlag = true;
      }
      // this.getSuppsList()
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.show = true;
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      let mtrlForm = {};
      mtrlForm = Object.assign(mtrlForm, this.mtrlForm);
      mtrlForm.mtrl_color = mtrlForm.mtrl_type === 0 ? mtrlForm.mtrl_color + 'C' : mtrlForm.mtrl_color;
      mtrlForm.mtrl_no =
        mtrlForm.mtrl_type === 0 ? mtrlForm.mtrl_no + '' + mtrlForm.mtrl_color + this.num_no : mtrlForm.mtrl_no + this.num_no;
      post(mtrlAPI.editMtrl, mtrlForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.warningTitle = '';
            this.$emit('isShow', this.isShow);
            this.initData();
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '物料编号重复'
            });
          } else if (res.data.code === 888) {
            let mg = res.data.msg;
            this.warningTitle = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            if (res.data.msg === '物料编号格式输入错误!') {
              this.mtrlForm.mtrl_no = null;
            } else {
              this.mtrlForm.mtrl_no = null;
              this.mtrlForm.mtrl_color = null;
              this.mtrlForm.mtrl_width = null;
              this.mtrlForm.mtrl_thick = null;
            }
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取账套accts
    getAccts() {
      get(acctAPI.getAllAcctsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.acctCopy = res.data.data;
            for (let i = 0; i < this.acctCopy.length; i++) {
              if (this.$cookies.get('userInfo').acct_id === this.acctCopy[i].acct_id) {
                this.acctCopy[i].disabled = true;
              }
            }
          } else if (res.data.code === 5) {
            console.log('  ');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取单位
    getMtrlType() {
      get(optnAPI.getAllContent, { perm_id: 10005 })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlTypeGroupOpt = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取成分
    getCompType() {
      get(optnAPI.getAllContent, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.compType = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取成分
    getCustList() {
      getNoCatch(custAPI.getAllCustsV1).then(({ data }) => {
        this.custList = Array.from(data, item => {
          return { value: item.cust_id, label: item.cust_name };
        });
      });
    },
    // 关闭特殊数据范围
    handleCloseDrawer() {
      let flag = false;
      for (let i = 0; i < this.mtrlForm.mtrl_acct_list.length; i++) {
        if (this.mtrlForm.mtrl_acct_list[i].acct_id === null) {
          flag = true;
        }
      }
      if (flag) {
        this.$message.warning('请把分享的账套补充完整');
      } else {
        if (this.acctPreseFlag) {
          this.$message.warning('请先保存或取消分享账套的编辑');
        } else {
          this.drawer = false;
          // this.$confirm('确认关闭？')
          // .then(_ => {
          //   this.drawer = false
          // })
          // .catch(_ => {});
        }
      }
    },
    // 保存分享账套
    preseType() {
      let temp = false;
      for (let i = 0; i < this.mtrlForm.mtrl_acct_list.length; i++) {
        if (this.mtrlForm.mtrl_acct_list[i].acct_id === null) {
          temp = true;
        }
      }
      if (temp) {
        this.$message.warning('请把账套名称补充完整');
      } else {
        this.$confirm('确认保存?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(res => {
            let acctList = JSON.parse(JSON.stringify(this.mtrlForm.mtrl_acct_list));
            this.mtrlAcctCope = this.mtrlAcctCope.filter(item => acctList.every(item1 => item.mtrl_acct_id !== item1.mtrl_acct_id));
            for (let i = 0; i < this.mtrlAcctCope.length; i++) {
              this.mtrlAcctCope[i].destroy_flag = 1;
            }
            acctList = acctList.concat(this.mtrlAcctCope);
            if (acctList.length > 0) {
              post(mtrlAPI.mtrlShareAccts, { mtrl_id: this.mtrlForm.mtrl_id, mtrl_acct_list: acctList })
                .then(res => {
                  if (res.data.code === 0) {
                    this.$message.success('保存成功');
                    // this.drawer = false
                    this.acctPreseFlag = false;
                    this.initData();
                  } else {
                    let mg = res.data.msg;
                    let tp = 'error';
                    this.$message({ message: mg, type: tp });
                  }
                })
                .catch(res => {
                  let mg = res.data.msg;
                  let tp = 'error';
                  this.$message({ message: mg, type: tp });
                });
            } else {
              this.$message.success('保存成功');
              this.acctPreseFlag = false;
              this.initData();
            }
          })
          .catch(() => {});
      }
    },
    // 取消分享账套
    cancelType() {
      this.$confirm('确认取消?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(res => {
          this.acctPreseFlag = false;
          this.initData();
        })
        .catch(() => {});
    },
    // 特殊数据范围添加
    addType() {
      let item = {
        acct_id: null
      };
      this.mtrlForm.mtrl_acct_list.push(item);
    },
    // 特殊数据范围添加删除
    delType(scope) {
      if (scope.row.disabled) {
        this.$message.error('该账套不能删除');
      } else {
        this.$confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.mtrlForm.mtrl_acct_list.splice(scope.$index, 1);
          })
          .catch(() => {});
      }
    },
    // 选择账套重复判断
    acctSelect(val) {
      if (val.row.acct_id !== null) {
        for (let i = 0; i < this.mtrlForm.mtrl_acct_list.length; i++) {
          if (i !== val.$index) {
            if (this.mtrlForm.mtrl_acct_list[i].acct_id === val.row.acct_id) {
              val.row.acct_id = null;
              this.$message({
                type: 'warning',
                message: '账套选择重复'
              });
              return;
            }
          }
        }
      }
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('mtrlForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 关闭drawer框
    handleClose(done) {},
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        if (!this.mtrlForm.imge_id) {
          this.mtrlForm.imge_id = res.data.imge_id;
        }
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.mtrlForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime, 0);
        this.mtrlForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime, 0);
      } else {
        let mg = res.msg;
        let tp = 'error';
        this.$message({ message: mg, type: tp });
      }
    },
    // 上传头像前
    beforeAvatarUpload() {},
    // 获取表单信息
    getmtrlInfo() {
      this.mtrlForm.mtrl_id = this.$route.query.form_id;
      get(mtrlAPI.getMtrlById, { mtrl_id: this.mtrlForm.mtrl_id })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlForm = JSON.parse(JSON.stringify(res.data.data.form));
            this.mtrlAcctCope = JSON.parse(JSON.stringify(this.mtrlForm.mtrl_acct_list));
            if (this.mtrlForm.mtrl_type === 0) {
              this.num_no = res.data.data.form.mtrl_no.substring(
                res.data.data.form.mtrl_no.lastIndexOf('C') + 1,
                res.data.data.form.mtrl_no.length
              );
              this.mtrlForm.mtrl_color = res.data.data.form.mtrl_color.substring(0, res.data.data.form.mtrl_color.length - 1);
              this.mtrlForm.mtrl_no = res.data.data.form.mtrl_no.substring(0, 2);
            } else {
              //为原面料时的编号
              this.num_no = res.data.data.form.mtrl_no.substr(res.data.data.form.mtrl_no.length - 1, 1);
              // 原面料 时需要的色号
              this.mtrlForm.mtrl_color = res.data.data.form.mtrl_color.substr(0, res.data.data.form.mtrl_color.length - 1);

              //编号
              this.mtrlForm.mtrl_no = res.data.data.form.mtrl_no.substr(0, 2);
            }
            this.btn = res.data.data.btn;
            let urlEng = JSON.parse(JSON.stringify(this.mtrlForm.imge_url));
            if (urlEng) {
              if (this.mtrlForm.imge_id) {
                this.dataBody.imge_id = this.mtrlForm.imge_id;
              } else {
                this.dataBody.imge_id = 0;
              }
              let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
              this.mtrlForm.imge_url =
                urlEng.indexOf('jpg') !== -1 ? urlEng : this.helper.picUrl(urlEng, 's', updateTime, this.mtrlForm.imge_source);
              this.mtrlForm.imge_urlO =
                urlEng.indexOf('jpg') !== -1 ? urlEng : this.helper.picUrl(urlEng, 'l', updateTime, this.mtrlForm.imge_source);
              this.imgForm.imge_url = JSON.parse(JSON.stringify(this.mtrlForm.imge_url));
              this.imgForm.imge_id = JSON.parse(JSON.stringify(this.mtrlForm.imge_id));
              this.imgForm.imge_urlO = JSON.parse(JSON.stringify(this.mtrlForm.imge_urlO));
            }

            if (this.show) {
              this.mtrlForm.imge_id = this.imgForm.imge_id;
              this.mtrlForm.imge_url = this.imgForm.imge_url;
              this.mtrlForm.imge_urlO = this.imgForm.imge_urlO;
              this.show = false;
            }
            this.mtrlForm.mtrl_price = this.helper.haveFour(this.mtrlForm.mtrl_price);
            this.mtrlForm.mtrl_weight = this.helper.reservedDigits(this.mtrlForm.mtrl_weight);
            this.mtrlForm.mtrl_thick = this.helper.reservedDigits(this.mtrlForm.mtrl_thick);
            this.mtrlForm.mtrl_width = this.helper.haveFour(this.mtrlForm.mtrl_width);
            this.stffForm.stff_name = this.mtrlForm.stff_name;
            this.stffForm.dept_name = this.mtrlForm.dept_name;
            this.stffForm.cptt_abbr = this.mtrlForm.cptt_abbr;
            this.stffForm.dept_team_name = this.mtrlForm.dept_team_name;
            this.stffForm.user_id = this.mtrlForm.user_id;
            this.stffForm.dept_id = this.mtrlForm.dept_id;
            this.stffForm.stff_id = this.mtrlForm.stff_id;
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          console.error(res);
        });
    },
    mtrlNoInput() {
      this.mtrlForm.mtrl_no = this.helper.keepEngNum1(this.mtrlForm.mtrl_no).toUpperCase();
      this.mtrlForm.mtrl_no = this.mtrlForm.mtrl_no.substr(0, 2);
    },

    mtrlColorInput() {
      this.mtrlForm.mtrl_color = this.helper.keepTNum(this.mtrlForm.mtrl_color);
      this.mtrlForm.mtrl_color = this.mtrlForm.mtrl_color.substr(0, 4);
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_export1 {
  position: absolute;
  top: 0;
  left: 120px;
}

.vd_popimg {
  width: 500px;
  height: 500px;
}

.vd_cussor {
  cursor: default;
}

::v-deep .mtrlNo .el-input-group__append {
  width: 70%;
  padding: 0;
}

::v-deep .mtrlcolor .el-input-group__append {
  width: 70%;
  padding: 0;
}

::v-deep .el-input-group__append {
  color: #000000;
}

.vd_draw {
  padding: 5% 5%;
}

.vd_color_red {
  color: #ff0000;
}

::v-deep .el-upload--text {
  border: 1px dashed #d9d9d9 !important;
}
</style>
